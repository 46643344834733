import React from "react";
import classNames from "classnames";
import SectionHeader from "../partials/SectionHeader";
import { SectionTilesProps } from "../../../utils/SectionProps";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const OurWorks = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-whatwedo",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap");

  const sectionHeader = {
    title: "Our",
    title_highlight: "Works",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container bg-img-our-work">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div
              className="tiles-item-our-work reveal-from-top content-has-shadow"
              data-reveal-delay="100"
            >
              <div className="item-our-work">
                <div className="d-flex flex-column">
                  <h5 className="title-our-work mt-0 mb-12">Smart Agent</h5>
                  <div className="testimonial-item-footer text-xs mb-0">
                    <p className="detail-our-work text-xs mb-0">
                      Customized Web Portal for insurance Brokerage firm to
                      track purchasing request, create quotation and Job
                      tracking to Commission calculation and payment using PaaS
                      and infrastructure on AWS....
                    </p>
                  </div>
                </div>
                {/* <div className="detail-link text-xxs">
                  <Link
                    className="link-highlight"
                    to={`/our-works/smart-agent-website`}
                    // target={"_blank"}
                  >
                    {`detail >`}
                  </Link>
                </div> */}
              </div>
            </div>

            <div
              className="tiles-item-our-work reveal-from-top content-has-shadow"
              data-reveal-delay="200"
            >
              <div className="item-our-work">
                <div className="d-flex flex-column">
                  <h5 className="title-our-work mt-0 mb-12">
                    Sale Portal Web App
                  </h5>
                  <div className="testimonial-item-footer mb-0">
                    <p className="detail-our-work text-xs mb-0">
                      The Sale Portal Web Application is developed to improve a
                      process for simple document workflow and information
                      management, keeping the information available and
                      accessible to the whole team....
                    </p>
                  </div>
                </div>
                {/* <div className="detail-link text-xxs">
                  <Link
                    className="link-highlight"
                    to={`/our-works/sale-portal-web-application`}
                    // target={"_blank"}
                  >
                    {`detail >`}
                  </Link>
                </div> */}
              </div>
            </div>

            <div
              className="last-tiles-item-our-work reveal-from-top content-has-shadow"
              data-reveal-delay="300"
            >
              <div className="item-our-work">
                <div className="d-flex flex-column">
                  <h5 className="title-our-work mt-0 mb-12">
                    Route Optimization
                  </h5>
                  <div className="testimonial-item-footer mb-0">
                    <p className="detail-our-work text-xs mb-0">
                      TradeSquare Software-as-a-Service is for route-optimizing
                      planning problems developed to improve routing process
                      efficiency and reduce time spent on route planning.
                      <br />
                      RO smart AI model implemented with user-customized
                      configuration, easy-to-use UI, and map-visualized with
                      Google Map integration....
                    </p>
                  </div>
                </div>
                {/* <div className="detail-link text-xxs">
                  <Link
                    className="link-highlight"
                    to={`/our-works/route-optimization`}
                    // target={"_blank"}
                  >
                    {`detail >`}
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="see-more-link text-xxs">
            <Link
              className="hover-see-more-link"
              to={`/our-works`}
              // target={"_blank"}
            >
              <p>see more...</p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

OurWorks.propTypes = propTypes;
OurWorks.defaultProps = defaultProps;

export default OurWorks;
