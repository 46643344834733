import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const PositionBottomContent = ({
  contentTitle,
  contentMessage,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {contentTitle && (
            <h5 className="position-detail-title">{contentTitle}</h5>
          )}
          {contentMessage && (
            <div
              className="position-detail-top-content reveal-from-top"
              data-reveal-delay="100"
            >
              {contentMessage}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

PositionBottomContent.propTypes = propTypes;
PositionBottomContent.defaultProps = defaultProps;

export default PositionBottomContent;
