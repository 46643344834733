import React, { useState, useRef, useEffect } from "react";
import { Link, Link as LinkPage, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Logo from "./partials/Logo";

import logo_gray from "./../../assets/images/logo_gray.svg";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  let location = useLocation();
  const page = location.pathname;
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div className={classNames("site-header-inner", "has-bottom-divider")}>
          {/* <Logo /> */}
          <Link to="/">
            <img
              src={logo_gray}
              alt="Trade Square"
              className="img-has-shadow size-img-header"
            />
          </Link>
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  "header-nav",
                  isActive ? "is-active" : "hidden"
                )}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li
                      className={classNames(
                        "pt-2 pb-2 ",
                        isActive ? "has-bottom-divider-for-menu" : "hidden"
                      )}
                    >
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/" || page === "/home"
                            ? "header-highlight"
                            : ""
                        } mx-1`}
                        to="/home"
                      >
                        Home
                      </LinkPage>
                    </li>
                    <li
                      className={classNames(
                        "pt-2 pb-2 ",
                        isActive ? "has-bottom-divider-for-menu" : "hidden"
                      )}
                    >
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/our-story" ? "header-highlight" : ""
                        } mx-1`}
                        to="/our-story"
                      >
                        Our Story
                      </LinkPage>
                    </li>
                    <li
                      className={classNames(
                        "pt-2 pb-2 ",
                        isActive ? "has-bottom-divider-for-menu" : "hidden"
                      )}
                    >
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/our-works"
                            ? // page.includes("/our-works/content/") ||
                              // page === "/our-works/sale-portal-web-application" ||
                              // page === "/our-works/smart-agent-website" ||
                              // page === "/our-works/smart-dashboard" ||
                              // page ===
                              //   "/our-works/super-fiber-self-service-portal" ||
                              // page === "/our-works/data-engineer-on-cloud" ||
                              // page === "/our-works/route-optimization" ||
                              // page ===
                              //   "/our-works/factory-planning-and-economic-analysis"
                              "header-highlight"
                            : ""
                        } mx-1`}
                        to="/our-works"
                      >
                        Our Works
                      </LinkPage>
                    </li>
                    <li
                      className={classNames(
                        "pt-2 pb-2 ",
                        isActive ? "has-bottom-divider-for-menu" : "hidden"
                      )}
                    >
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/our-services"
                            ? // page.includes("/our-works/content/") ||
                              // page === "/our-works/sale-portal-web-application" ||
                              // page === "/our-works/smart-agent-website" ||
                              // page === "/our-works/smart-dashboard" ||
                              // page ===
                              //   "/our-works/super-fiber-self-service-portal" ||
                              // page === "/our-works/data-engineer-on-cloud" ||
                              // page === "/our-works/route-optimization" ||
                              // page ===
                              //   "/our-works/factory-planning-and-economic-analysis"
                              "header-highlight"
                            : ""
                        } mx-1`}
                        to="/our-services"
                      >
                        Our Services
                      </LinkPage>
                    </li>
                    <li
                      className={classNames(
                        "pt-2 pb-2 ",
                        isActive ? "has-bottom-divider-for-menu" : "hidden"
                      )}
                    >
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/careers" ||
                          page === "/careers/jr-full-stack-developer" ||
                          page === "/careers/front-end-developer" ||
                          page === "/careers/back-end-developer"
                            ? "header-highlight"
                            : ""
                        } mx-1`}
                        to="/careers"
                      >
                        Careers
                      </LinkPage>
                    </li>
                    <li className="pt-2 pb-2 ">
                      <LinkPage
                        className={`menu-w-100 ${
                          page === "/contact-us" ? "header-highlight" : ""
                        } mx-1`}
                        to="/contact-us"
                      >
                        Contact Us
                      </LinkPage>
                    </li>
                  </ul>
                  {!hideSignin}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
