import React from "react";
import classNames from "classnames";
// import SectionHeader from "../partials/SectionHeader";
import { SectionTilesProps } from "../../../utils/SectionProps";

import page_not_found from "./../../../assets/images/page_not_found.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const PageNotFoundImg = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap justify-content-center align-items-center px-3"
  );

  // const sectionHeader = {
  //   title: "Our",
  //   title_highlight: "Story",
  // };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content pb-0" /> */}
          <div className={tilesClasses}>
            <div
              className="tiles-item-our-story reveal-from-top"
              data-reveal-delay="100"
            >
              <div className="d-flex flex-column">
                <div className="h-content-for-w-img-page-not-found">
                  <img
                    src={page_not_found}
                    alt="Our story"
                    className="w-img-page-not-found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PageNotFoundImg.propTypes = propTypes;
PageNotFoundImg.defaultProps = defaultProps;

export default PageNotFoundImg;
