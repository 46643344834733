import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";

// import sections
import OurWorkTopImg from "../../components/sections/our-works/OurWorkTopImg";
import OurProject from "../../components/sections/our-works/OurProject";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const OurWork = () => {
  const childRef = useRef();
  let location = useLocation();
  const page = location.pathname;

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    goToTop();
  }, [location, page]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          {/* {page} */}
          <div className="bg-top" />
          <OurWorkTopImg id="OurWorkTopImg" />
          <OurProject id="OurProject" />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default OurWork;
