import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const PositionTopContent = ({
  positionName,
  contentMessage,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {positionName && (
            <div className="position-detail-header">{positionName}</div>
          )}
          {contentMessage && (
            <div
              className="position-detail-top-content reveal-from-top"
              data-reveal-delay="100"
            >
              {contentMessage}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

PositionTopContent.propTypes = propTypes;
PositionTopContent.defaultProps = defaultProps;

export default PositionTopContent;
