import React, { useRef } from "react";

// import sections
import PositionDetail from "../../../components/sections/partials/PositionDetail";
 
const SolutionArchitect = () => {
  const childRef = useRef();
  const positionName = "Solution Architect on Cloud";
  const contentMessage = (
    <div className="text-xs my-0"> 
        <li className="m-0">
          <b>Location:</b> Remote with minimum 2-day on-site per week
        </li>
        <li className="m-0">
        <b>Job Type:</b> Full-time
        </li>
    </div>
  );

  const jobDescriptions = {
    title: "Job Descriptions",
    content: (
      <p className="text-xs my-0">
        We are seeking a highly experienced and skilled Senior Solution Architect with a strong background in cloud services and software development to join our team. The ideal candidate will have a track record of successfully designing and implementing complex cloud-based solutions, and will be able to lead and mentor other team members in the development and deployment of these solutions.

        <h5 style={{ marginTop: '100px' }} className="position-detail-title">Responsibilities:</h5>
        <li className="m-0">
          Lead the design and implementation of complex cloud-based solutions using a variety of cloud services from one of the global providers such as AWS, Azure or GCP
        </li>
        <li className="m-0">
          Collaborate with project teams to identify and evaluate cloud service requirements
        </li>
        <li className="m-0">
          Work closely with sales team and capable to consult with client for technical requirement
        </li>
        <li className="m-0">
          Mentor and provide guidance to other team members on cloud best practices, service implementations and architecture design
        </li>
        <li className="m-0">
          Develop and maintain solution Infra-as-a-Code template
        </li>
        <li className="m-0">
          Develop and maintain CICD for modern web applications utilizing SaaS and PaaS service
        </li>
        <li className="m-0">
          Stay up-to-date on the latest cloud service and related technologies
        </li> 
      </p>
    ),
  };

  const responsibilities = {
    title: null,
    content: null,
  };

  const requirements = {
    title: "Requirements",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
         Bachelor's degree in Computer Science, Engineering, or a related field
        </li>
        <li className="m-0">
         Minimum of 3 years of experience as a Solution Architect, with a focus on cloud services
        </li>
        <li className="m-0">
         Expertise in designing and implementing scalable, highly available, and fault-tolerant systems on at least one cloud service provider, preferably AWS
        </li>
        <li className="m-0">
         Strong understanding of IaaS, PaaS and SaaS cloud services, including but not limited to EC2, Lambda, API-Gateway, Amplify, Cognito, S3, ECS, EKS
        </li>
        <li className="m-0">
         Strong understanding of virtualization, dockerization
        </li>
        <li className="m-0">
         Experience with DataLake or LakeHouse architecture design on Cloud is an significant advantage
        </li>
        <li className="m-0">
         Experience implementing complex, multi-tier applications utilizing cloud PaaS services
        </li>
        <li className="m-0">
         Experience migrating web application follows at least one of lift-and-shift, re-platform, modernize patterns to cloud environment
        </li>
        <li className="m-0">
         Experience in software development especially backend or API-layer, including experience with at least one programming language (e.g. Java, Python, GoLang)
        </li>
        <li className="m-0">
         Cloud certification(s) preferred (e.g. AWS Certified Solutions Architect, Microsoft Certified: Azure Solutions Architect Expert)
        </li>
        <li className="m-0">
         Excellent communication and collaboration skills
        </li>
        <li className="m-0">Understanding of agile processes is a plus.</li>
        <li className="m-0">Knowledge of unit testing.</li>
        <li className="m-0">Be able to learn and adapt to new tech stack.</li>
        <li className="m-0">Able to communicate in English.</li>
      </p>
    ),
  };

  const benefits = {
    title: "Benefits",
    content: (
      <p className="text-xs my-0">
        <li className="m-0">
         Bachelor's degree in Computer Science, Engineering, or a related field
        </li>
        <li className="m-0">
         10 days of annual leave.
        </li>
        <li className="m-0">
         AWS or other cloud provider Training and certification budget.
        </li>
        <li className="m-0">
         Thailand Social Security Fund (ประกันสังคม).
        </li>
        <li className="m-0">
         At least 14 days holiday on a calendar year.
        </li>
        <li className="m-0">
         Work from home on a daily basis or as requested occasionally if required.
        </li>
        <li className="m-0">
         Flexible working hours but at least 8 hours/day.
        </li>
        <li className="m-0">
         Annual raise and bonus based on personal performance and company performance
        </li>
      </p>
    ),
  };

  const applyNow = {
    title: "Apply Now",
    content: (
      <p className="text-xs my-0">
          <li className="m-0">
            <b>Email:</b> sasina@tradesquareltd.com
          </li>
          <li className="m-0">
            <b>Tel:</b> 0613910641
          </li>
      </p>
    ),
  };

  const previousPageText = "Careers";

  /**
   * {`/our-works/content/#projectId`}
   * #projectId must equal to 'id' in 'projectDetailLink' from ProjectContent
   */
  const previousPageLink = "/careers";

  return (
    <PositionDetail
      childRef={childRef}
      positionName={positionName}
      contentMessage={contentMessage}
      jobDescriptionsTitle={jobDescriptions.title}
      jobDescriptionsContent={jobDescriptions.content}
      responsibilitiesTitle={responsibilities.title}
      responsibilitiesContent={responsibilities.content}
      requirementsTitle={requirements.title}
      requirementsContent={requirements.content}
      benefitsTitle={benefits.title}
      benefitsContent={benefits.content}
      applyNowTitle={applyNow.title}
      applyNowContent={applyNow.content}
      previousPageText={previousPageText}
      previousPageLink={previousPageLink}
    />
  );
};

export default SolutionArchitect;
