import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
// import { Link as LinkPage } from "react-router-dom";
import Image from "../../elements/Image";
// import logo from "./../../../assets/images/logo.svg";
import logo_gray from "./../../../assets/images/logo_gray.svg";

const Logo = ({ height, className, ...props }) => {
  const classes = classNames("brand", className);
  const h = height ? height : 70;
  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="Home" href="https://www.tradesquareltd.com">
          <Image
            className="img-has-shadow"
            src={logo_gray}
            style={{ height: h }}
            alt="Trade Square"
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
