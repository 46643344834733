import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";

// section
import OurAWS from "./OurAWS";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const OurService = ({ history }) => {
  const childRef = useRef();
  let location = useLocation();
  const page = location.pathname;
  useEffect(() => {
    document.body.classList.add("is-loaded");
    window.scrollTo(0, 0);
    setTimeout(() => {
      childRef.current.init();
    }, 500);
    trackPage(page);
  }, [location, page]);

  useEffect(() => {}, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          {/* {page} */}
          <div className="bg-top" />
          <OurAWS />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default OurService;
