import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const CareerList = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap justify-content-center align-items-center px-1 mb-4"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            {/* Solution Architect on Cloud */}
            <div
              className="px-3 py-2 w-100 reveal-from-left"
              data-reveal-delay="100"
            >
              <Link
                className="hover-career-list-link"
                to={`/careers/solution-architect`}
              >
                <div className="tiles-item-career-list">
                  <li className="career-title text-sm m-0">
                  Solution Architect on Cloud
                  </li>
                  <p className="text-xxs m-0">1 Position</p>
                </div>
              </Link>
            </div>
            {/* Jr. Full-Stack Developer */}
            <div
              className="px-3 py-2 w-100 reveal-from-left"
              data-reveal-delay="100"
            >
              <Link
                className="hover-career-list-link"
                to={`/careers/jr-full-stack-developer`}
              >
                <div className="tiles-item-career-list">
                  <li className="career-title text-sm m-0">
                    Jr. Full-Stack Developer
                  </li>
                  <p className="text-xxs m-0">1 Position</p>
                </div>
              </Link>
            </div>

            {/* Front-end Developer */}
            <div
              className="px-3 py-2 w-100 reveal-from-left"
              data-reveal-delay="100"
            >
              <Link
                className="hover-career-list-link"
                to={`/careers/front-end-developer`}
              >
                <div className="tiles-item-career-list">
                  <li className="career-title text-sm m-0">
                    Front-end Developer
                  </li>
                  <p className="text-xxs m-0">1 Position</p>
                </div>
              </Link>
            </div>

            {/* Back-end Developer */}
            <div
              className="px-3 py-2 w-100 reveal-from-left"
              data-reveal-delay="100"
            >
              <Link
                className="hover-career-list-link"
                to={`/careers/back-end-developer`}
              >
                <div className="tiles-item-career-list">
                  <li className="career-title text-sm m-0">
                    Back-end Developer
                  </li>
                  <p className="text-xxs m-0">1 Position</p>
                </div>
              </Link>
            </div>

             

          </div>
        </div>
      </div>
    </section>
  );
};

CareerList.propTypes = propTypes;
CareerList.defaultProps = defaultProps;

export default CareerList;
