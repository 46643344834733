import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import who_are_we_p_bo from "./../../../assets/images/our-story/who_are_we_p_bo.svg";
import who_are_we_p_kwang from "./../../../assets/images/our-story/who_are_we_p_kwang.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const WhoWeAre = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-whoweare",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap center-content");

  const pBoClassesMdUp = classNames("row-content show-content-md-up mb-48");
  const pBoClassesSmDown = classNames("row-content show-content-sm-down mb-48");
  const pKwangClasses = classNames("row-content");

  const sectionHeader = {
    title: "Who",
    title_highlight: "We Are",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <p
              className="text-xs text-center mb-48 px-3 reveal-from-top"
              data-reveal-delay="200"
            >
              Trade Square is a limited company registered in Thailand in 2015.
              <br />
              We are specialized in Cloud Solution and customized software and
              platform development.
            </p>
            {/* P' Bo */}
            <div className={pBoClassesMdUp}>
              <div
                className="tiles-item-whoweare-top-left reveal-from-left"
                data-reveal-delay="200"
              >
                <p className="text-xs my-0">
                  “At Trade Square we are always innovating and looking for
                  opportunities to invest in people and technology. With our
                  core values and our proven method of work, we gain trust and
                  buy-in from our customer and help industries deliver
                  solutions.
                  <br />
                  <br />
                  Our Goal is to help businesses transform digitally to grow and
                  maximize the benefits of technology with sustainability while
                  always looking for opportunities for our team to raise our
                  capabilities and competency.”
                </p>
                <p className="text-xs my-0 pt-3">
                  - Kan Boonprakub
                  <br />
                  CEO of TradeSquare
                </p>
              </div>
              <div
                className="tiles-item-whoweare-top-right reveal-from-left"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-300px">
                    <img
                      src={who_are_we_p_bo}
                      alt="Kan Boonprakub"
                      className="w-img-300px img-has-shadow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={pBoClassesSmDown}>
              <div
                className="tiles-item-whoweare-bottom-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-300px">
                    <img
                      src={who_are_we_p_bo}
                      alt="Kan Boonprakub"
                      className="w-img-300px img-has-shadow"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-whoweare-bottom-right reveal-from-right"
                data-reveal-delay="200"
              >
                <p className="text-xs my-0">
                  “At Trade Square we are always innovating and looking for
                  opportunities to invest in people and technology. With our
                  core values and our proven method of work, we gain trust and
                  buy-in from our customer and help industries deliver
                  solutions.
                  <br />
                  <br />
                  Our Goal is to help businesses transform digitally to grow and
                  maximize the benefits of technology with sustainability while
                  always looking for opportunities for our team to raise our
                  capabilities and competency.”
                </p>
                <p className="text-xs my-0 pt-3">
                  - Kan Boonprakub
                  <br />
                  CEO of TradeSquare
                </p>
              </div>
            </div>
            {/* P' Kwang */}
            <div className={pKwangClasses}>
              <div
                className="tiles-item-whoweare-bottom-left reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="h-content-for-w-img-300px">
                    <img
                      src={who_are_we_p_kwang}
                      alt="Yuttakorn Yuttakornkit"
                      className="w-img-300px img-has-shadow"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item-whoweare-bottom-right reveal-from-right"
                data-reveal-delay="200"
              >
                <p className="text-xs my-0">
                  “Lifelong learning and constant pursuit of technological
                  advancements are essential for our team. By adopting these
                  ideas, we can deliver the highest quality, achieve high
                  performance, and carefully consider cost efficiency in our
                  work.”
                </p>
                <p className="text-xs my-0 pt-3">
                  - Yuttakon Yuttakonkit (Ph.D.)
                  <br />
                  CTO of TradeSquare
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WhoWeAre.propTypes = propTypes;
WhoWeAre.defaultProps = defaultProps;

export default WhoWeAre;
