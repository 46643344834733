import React, { useEffect } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";

import prj_sale_portal_01 from "./../../../assets/images/our-work/prj_sale_portal_01.svg";
import prj_smart_agent_01 from "./../../../assets/images/our-work/prj_smart_agent_01.svg";
import prj_smart_agent_logo from "./../../../assets/images/our-work/prj_smart_agent_logo.svg";
import prj_smart_dashboard_01 from "./../../../assets/images/our-work/prj_smart_dashboard_01.svg";
import prj_super_fiber_01 from "./../../../assets/images/our-work/prj_super_fiber_01.svg";
import prj_data_engineer_01 from "./../../../assets/images/our-work/prj_data_engineer_01.svg";
import prj_route_optimization_01 from "./../../../assets/images/our-work/prj_route_optimization_01.svg";
import prj_route_optimization_logo from "./../../../assets/images/our-work/prj_route_optimization_logo.svg";
import prj_factory_planning_01 from "./../../../assets/images/our-work/prj_factory_planning_01.svg";

import ProjectContent from "../../layout/partials/ProjectContent";
import { useHistory, useParams } from "react-router-dom";
import { scrollToTarget } from "../../../function/scrollToTarget";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const OurProject = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap");

  const { id } = useParams();
  let history = useHistory();
  const duration = 200;

  useEffect(() => {
    // console.log("id :>> ", id);
    const target = document.getElementById(id);
    if (target) {
      scrollToTarget(target, duration);
    } else {
      history.push("/our-works");
    }
  }, [history, id]);

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            {/* Sale Portal Web Application */}
            <ProjectContent
              id="sale-portal-web-application"
              projectId="sale-portal-web-application"
              projectName={"Sale Portal Web Application"}
              projectImg={prj_sale_portal_01}
              tagNames={["Cloud", "Consults", "Software"]}
              upperDetail={
                <p className="m-0 p-0">
                  The Sale Portal Web Application is developed to improve a
                  process for simple document workflow and information
                  management, keeping the information available and accessible
                  to the whole team. The pre-defined information will be
                  consistent across all forms generated as well as the
                  calculation mechanism to semi-automate the process, making it
                  easy and flexible for users to adjust as well as save time and
                  cost in producing the documents.
                </p>
              }
              lowerDetail={
                <p className="m-0 p-0">
                  “ระบบสำหรับกระบวนการทำงานผ่าน Webbase
                  มีส่วนช่วยให้ทีมงานสามารถทำงานมีประสิทธิภาพมากขึ้น
                  โดยเป็นฐานข้อมูลที่สำคัญ, ลดการทำงานซ้ำซ้อน,
                  เพิ่มความถูกต้องของข้อมูลโดยเฉพาะเรื่องของตัวเลข, สามารถ
                  Tracking ขั้นตอนการทำงานได้, สามารถทำงานผ่านมือถือได้
                  และดึงข้อมูลในระบบออกมาในรูปแบบ Excel ได้
                  <br />
                  การทำงานด้วยระบบข้อมูลตอบโจทย์ลักษณะการทำงานแบบ Routine
                  และมีข้อจำกัดด้วยจำนวนคนแต่ปริมาณข้อมูลการทำงานมีจำนวนมาก”
                </p>
              }
              projectOwner={"Customer Project Owner"}
              // projectDetailLink={"/our-works/sale-portal-web-application"}
            />

            {/* Smart Agent Website */}
            <ProjectContent
              id="smart-agent-website"
              projectId="smart-agent-website"
              projectName={"Smart Agent Website"}
              projectImg={prj_smart_agent_01}
              projectLogoImg={prj_smart_agent_logo}
              tagNames={["Cloud", "Consults", "Software"]}
              upperDetail={
                <p className="m-0 p-0">
                  Customized Web Portal for insurance Brokerage firm to track
                  purchasing request, create quotation and Job tracking to
                  Commission calculation and payment using PaaS and
                  infrastructure on AWS.
                </p>
              }
              // lowerDetail={
              //   <p className="m-0 p-0">
              //     “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              //     do eiusmod tempor incididunt ut labore et dolore magna aliqua”
              //   </p>
              // }
              // projectOwner={"Customer"}
              // projectDetailLink={"/our-works/smart-agent-website"}
            />

            {/* Smart Dashboard */}
            <ProjectContent
              id="smart-dashboard"
              projectId="smart-dashboard"
              projectName={"Smart Dashboard"}
              projectImg={prj_smart_dashboard_01}
              tagNames={["Analytics", "Cloud", "Consults"]}
              upperDetail={
                <p className="m-0 p-0">
                  Migrated Data Warehouse to a cloud platform and implemented
                  Data Visualization Dashboards with Tableau for the business
                  team to access the daily information, monitor the operation,
                  and utilize it for meetings and discussions within their team
                  and with clients.
                </p>
              }
              // projectDetailLink={"/our-works/smart-dashboard"}
            />

            {/* Super Fiber Self Service Portal */}
            <ProjectContent
              id="super-fiber-self-service-portal"
              projectId="super-fiber-self-service-portal"
              projectName={"Super Fiber Self Service Portal"}
              projectImg={prj_super_fiber_01}
              tagNames={["Cloud", "Software"]}
              upperDetail={
                <p className="m-0 p-0">
                  Integrated with one of Internet Service Providers internal
                  system, Online Self Service allows customer to check coverage
                  of the ISV broadband and apply for package. Web Portal comes
                  with fully customized CMS for staff to update content and
                  package.
                </p>
              }
              // projectDetailLink={"/our-works/super-fiber-self-service-portal"}
            />

            {/* Data Engineer on Cloud */}
            <ProjectContent
              id={"data-engineer-on-cloud"}
              projectId={"data-engineer-on-cloud"}
              projectName={"Data Engineer on Cloud"}
              projectImg={prj_data_engineer_01}
              tagNames={["Cloud", "Consults"]}
              upperDetail={
                <p className="m-0 p-0">
                  Design and develop a hybrid cloud data migration platform for
                  a customer’s on-premise to a Cloud service using Infra-as-Code
                  infrastructures DevOps process.
                </p>
              }
              // projectDetailLink={"/our-works/data-engineer-on-cloud"}
            />

            {/* Route Optimization */}
            <ProjectContent
              id={"route-optimization"}
              projectId={"route-optimization"}
              projectName={"Route Optimization"}
              projectImg={prj_route_optimization_01}
              projectLogoImg={prj_route_optimization_logo}
              tagNames={["Analytics", "Cloud", "Software"]}
              upperDetail={
                <p className="m-0 p-0">
                  TradeSquare Software-as-a-Service is for route-optimizing
                  planning problems developed to improve routing process
                  efficiency and reduce time spent on route planning. RO smart
                  AI model implemented with user-customized configuration,
                  easy-to-use UI, and map-visualized with Google Map
                  integration.
                </p>
              }
              // projectDetailLink={"/our-works/route-optimization"}
            />

            {/* Real-time Integrated Factory Planning and Economic Analysis */}
            <ProjectContent
              id={"factory-planning-and-economic-analysis"}
              projectId={"factory-planning-and-economic-analysis"}
              projectName={
                "Real-time Integrated Factory Planning and Economic Analysis"
              }
              projectImg={prj_factory_planning_01}
              tagNames={["Analytics", "Software"]}
              upperDetail={
                <p className="m-0 p-0">
                  Integrate real-time data from operation field and other data
                  source into a single system. Project and simulate production
                  based on real-time for economic analysis. Visualized the data
                  into real time and live dashboard.
                </p>
              }
              // projectDetailLink={
              //   "/our-works/factory-planning-and-economic-analysis"
              // }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

OurProject.propTypes = propTypes;
OurProject.defaultProps = defaultProps;

export default OurProject;
