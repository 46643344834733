import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const LinkNav = ({
  previousPageText,
  previousPageLink,
  currentPageText,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-nav-link",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="nav-link-row text-xxs">
            <div className="d-flex flex-row align-items-center">
              <Link className="nav-link" to={`${previousPageLink}`}>
                {`${previousPageText}`}
              </Link>
              <div className="px-2">{`>`}</div>
            </div>
            <div>{`${currentPageText}`}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

LinkNav.propTypes = propTypes;
LinkNav.defaultProps = defaultProps;

export default LinkNav;
