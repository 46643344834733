import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import what_we_value_01 from "./../../../assets/images/our-story/what_we_value_01.svg";
import what_we_value_02 from "./../../../assets/images/our-story/what_we_value_02.svg";
import what_we_value_03 from "./../../../assets/images/our-story/what_we_value_03.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const WhatWeValue = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap center-content");

  const sectionHeader = {
    title: "What",
    title_highlight: "We Value",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-top" data-reveal-delay="100">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_value_01}
                    alt="What we value - Your trust"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Your Trust</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    We propose viable solution, prove our methods, express our
                    thoughts genuinely
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_value_02}
                    alt="What we value - Process driven"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Process Driven</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    Strong but simple process and practices in every steps
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="300">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center d-flex-1 mb-32">
                  <img
                    src={what_we_value_03}
                    alt="What we value - Quality oriented"
                    className="width-auto h-250px"
                  />
                </div>
                <h5 className="mt-0 mb-12">Quality Oriented</h5>
                <div className="testimonial-item-footer mb-0">
                  <p className="text-xs mb-0">
                    Emphasize on the quality of deliverables in every steps as
                    key success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WhatWeValue.propTypes = propTypes;
WhatWeValue.defaultProps = defaultProps;

export default WhatWeValue;
