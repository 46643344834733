import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ScrollReveal from "../../../utils/ScrollReveal";

// import sections
import NavLink from "./NavLink";
import PositionTopContent from "./PositionTopContent";
import PositionContent from "./PositionContent";
import PositionBottomContent from "./PositionBottomContent";
import ListOfContent from "./ListOfContent";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const PositionDetail = ({
  childRef,
  positionName,
  contentMessage,
  jobDescriptionsTitle,
  jobDescriptionsContent,
  responsibilitiesTitle,
  responsibilitiesContent,
  requirementsTitle,
  requirementsContent,
  benefitsTitle,
  benefitsContent,
  applyNowTitle,
  applyNowContent,
  previousPageText,
  previousPageLink,
  ...props
}) => {
  let location = useLocation();
  const page = location.pathname;

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    goToTop();
  }, [childRef, location, page]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          <div className="bg-top" />
          <NavLink
            id="NavLink"
            previousPageText={previousPageText}
            previousPageLink={previousPageLink}
            currentPageText={positionName}
          />
          <ListOfContent
            jobDescriptions={jobDescriptionsTitle && jobDescriptionsContent}
            responsibilities={responsibilitiesTitle && responsibilitiesContent}
            requirements={requirementsTitle && requirementsContent}
            benefits={benefitsTitle && benefitsContent}
            applyNow={true}
          />
          {positionName && contentMessage && (
            <PositionTopContent
              id="PositionTopContent"
              positionName={positionName}
              contentMessage={contentMessage}
            />
          )}
          {jobDescriptionsTitle && jobDescriptionsContent && (
            <PositionContent
              id="JobDescriptions"
              contentTitle={jobDescriptionsTitle}
              contentMessage={jobDescriptionsContent}
            />
          )}
          {responsibilitiesTitle && responsibilitiesContent && (
            <PositionContent
              id="Responsibilities"
              contentTitle={responsibilitiesTitle}
              contentMessage={responsibilitiesContent}
            />
          )}
          {requirementsTitle && requirementsContent && (
            <PositionContent
              id="Requirements"
              contentTitle={requirementsTitle}
              contentMessage={requirementsContent}
            />
          )}
          {benefitsTitle && benefitsContent && (
            <PositionContent
              id="Benefits"
              contentTitle={benefitsTitle}
              contentMessage={benefitsContent}
            />
          )}
          <PositionBottomContent
            id="ApplyNow"
            contentTitle={applyNowTitle}
            contentMessage={applyNowContent}
          />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default PositionDetail;
