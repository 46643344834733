import React from "react";
import classNames from "classnames";
import SectionHeader from "../partials/SectionHeader";
import { SectionTilesProps } from "../../../utils/SectionProps";

import our_work_img from "./../../../assets/images/our-work/our_work.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const OurWorkTopImg = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-ourwork",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap justify-content-center align-items-center px-1"
  );

  const sectionHeader = {
    title: "Our",
    title_highlight: "Works",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div
              className="tiles-item-our-story reveal-from-top"
              data-reveal-delay="100"
            >
              <div className="d-flex flex-column">
                <div className="h-content-for-w-img-100vw">
                  <img
                    src={our_work_img}
                    alt="Our work"
                    className="w-img-100vw"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OurWorkTopImg.propTypes = propTypes;
OurWorkTopImg.defaultProps = defaultProps;

export default OurWorkTopImg;
