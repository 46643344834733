import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";

import our_services_01 from "./../../../assets/images/home/our_services_01.svg";
import our_services_02 from "./../../../assets/images/home/our_services_02.svg";
import our_services_03 from "./../../../assets/images/home/our_services_03.svg";
import our_services_04 from "./../../../assets/images/home/our_services_04.svg";
import our_services_05 from "./../../../assets/images/home/our_services_05.svg";
import our_services_05_logo from "./../../../assets/images/home/our_services_05_logo.svg";

import aws from "./../../../assets/images/home/aws.svg";
import awsxts from "./../../../assets/images/home/awsxts.svg";
import { Link } from "react-router-dom";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const OurServices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    // topOuterDivider && "has-top-divider",
    // bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner-ourservice",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  console.log('innerClasses :>> ', innerClasses);

  const tilesClasses = classNames("tiles-wrap");

  const sectionHeader = {
    title: "Our",
    title_highlight: "Services",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content pb-0" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-top" data-reveal-delay="100">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-160px">
                    <img
                      src={our_services_01}
                      alt="Our services - Project management"
                      className="w-img-160px"
                    />
                  </div>
                  <h5 className="my-0">Project Management</h5>
                </div>
                <div className="custom-mb">
                  <p className="text-xs mb-0">
                    With our experience and proven methodology, our professional
                    team help customer drive project to success from Kick-off to
                    launch day.
                    <br></br>
                    <br></br>
                    We constantly estimate and evaluate to ensure that project
                    execute within scope and make aware of risks and
                    uncertainties.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-160px">
                    <img
                      src={our_services_02}
                      alt="Our services - Data analytic solution"
                      className="w-img-160px"
                    />
                  </div>
                  <h5 className="my-0">Data Analytic Solution</h5>
                </div>
                <div className="custom-mb">
                  <p className="text-xs mb-0">
                    In today business, data is the crucial factor to win the
                    market, both to improve operation for cost saving and better
                    ROI or gain insight
                    <br></br>
                    <br></br>
                    Our data specialist helps analyze, not just your data but
                    also the process of acquiring and using the data. Build,
                    design and improve the whole data platform for you with
                    latest analytic technology.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="100">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-160px">
                    <img
                      src={our_services_03}
                      alt="Our services - Customize software & platform"
                      className="w-img-160px"
                    />
                  </div>
                  <h5 className="my-0">Customize Software & Platform</h5>
                </div>
                <div>
                  <p className="text-xs mb-0">
                    Your desires to launch web and mobile application make
                    simple. With our expertise in app development, we look into
                    your needs and constraints then propose solution that meet
                    those criterias.
                    <br></br>
                    <br></br>
                    From analyze, design, implementation and monitor for
                    improvement, we work with you through feedback loop so that
                    you gain utmost satisfaction with our deliverables.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-top" data-reveal-delay="200">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center d-flex-1 mb-16">
                  <div className="h-content-for-w-img-160px">
                    <img
                      src={our_services_04}
                      alt="Our services - Cloud solutions"
                      className="w-img-160px"
                    />
                  </div>
                  <h5 className="my-0">Cloud Solutions</h5>
                </div>
                <div>
                  <p className="text-xs mb-0">
                    Digital transformation can help improve business process and
                    gain business value significantly.
                    <br></br>
                    <br></br>
                    With our deep understanding of cloud technology and cloud
                    economic, we analyze your business process and/or existing
                    IT infrastructure and make suggestion for possibility of
                    leveraging cloud technology or migration for cost saving,
                    ROI, innovations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h5>AWS Partner Select Tier Services</h5>
            <div className="aws-logo">
              <center>
                <img
                  style={{ height: "100px" }}
                  src={awsxts}
                  alt="AWS Partner Select Tier Services"
                />
              </center>
            </div>
            <div className="row-content awsxts-base">
              <div
                className="reveal-from-left awsxts-content"
                data-reveal-delay="200"
              >
                <p
                  className="text-left"
                  style={{ textAlign: "left", fontSize: "18px" }}
                >
                  TradeSquare has proudly attained the coveted AWS Partner
                  Select Tier status. With a relentless commitment to innovation
                  and excellence, TradeSquare stands at the forefront of the
                  technology landscape.
                  <br></br>
                  <br></br>
                  This significant achievement underscores our profound
                  expertise in leveraging Amazon Web Services (AWS) to deliver
                  cutting-edge solutions that empower businesses to thrive in
                  the digital age.
                  <br></br>
                  <br></br>
                  As an AWS Partner Select Tier member, We has demonstrated a
                  remarkable ability to architect, build, migrate, and manage
                  workloads on the AWS platform. Our deep-seated collaboration
                  with AWS reflects a shared vision of transforming industries
                  through cloud-based transformation. We unrivaled proficiency,
                  paired with our dedication to customer success, solidifies our
                  position as a trusted partner capable of unlocking the full
                  potential of cloud computing for small business and
                  enterprises.
                </p>
              </div>
              <div className="reveal-from-left" data-reveal-delay="200">
                <div className="project-logo " style={{ padding: "16%" }}>
                  <div className="h-content-for-w-project-logo">
                    <img src={aws} alt="aws" className=" img-has-shadow" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>

          {/* New section */}
          <div style={{ marginTop: "50px" }}>
            <div
              className="row-content awsxts-base"
              style={
                {
                  // whiteSpace: 'nowrap',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis'
                }
              }
            >
              <div
                className="reveal-from-left awsxts-content"
                data-reveal-delay="200"
              >
                <div className="contain-header-our-srevices-05">
                  <img
                    style={{ height: "167px" }}
                    src={our_services_05_logo}
                    alt="AWS Partner Select Tier Services"
                  />
                  <h1>AWS Well-Architected Framework Review</h1>
                </div>
                <div
                  className="text-left"
                  style={{
                    textAlign: "left",
                    fontSize: "18px",
                    textWrap: "wrap",
                  }}
                >
                  As an AWS Select Tier Partner specializing in the AWS
                  Well-Architected Framework, Trade Square focuses on delivering
                  client-centered excellence across the six foundational
                  pillars: Operational Excellence, Security, Reliability,
                  Performance Efficiency, Cost Optimization, and Sustainability.
                  Our strategy revolves around targeted client engagement,
                  leveraging these pillars to assist businesses in achieving
                  optimal AWS infrastructures.
                </div>
                <Link to="/our-services">
                  <button
                    style={{
                      padding: "0 20px",
                      border: "none",
                      height: "53px",
                      background: "#A93346",
                      borderRadius: "5px",
                      marginTop: "40px",
                      color: "white",
                    }}
                  >
                    More details & Get started...
                  </button>
                </Link>
              </div>
              <div className="reveal-from-left" data-reveal-delay="200">
                <div className="project-logo " style={{ padding: "6%" }}>
                  <div className="h-content-for-w-project-logo">
                    <img
                      src={our_services_05}
                      alt="aws"
                      className=" img-has-shadow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OurServices.propTypes = propTypes;
OurServices.defaultProps = defaultProps;

export default OurServices;
