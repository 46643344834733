import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
// import { useLocation } from "react-router-dom";

const ProjectTag = ({ className, tagName, projectId, ...props }) => {
  // let location = useLocation();
  // const page = location.pathname;
  const outerClasses = classNames("project-tag-outer");
  const innerClasses = classNames("project-tag", className);

  return (
    <div className={outerClasses}>
      <div className={innerClasses}>
        <Link className="p-0 m-0" to={projectId} href="">
          {tagName ? tagName : "-"}
        </Link>
      </div>
    </div>
  );
};

export default ProjectTag;
